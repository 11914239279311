<template>
  <div class="invest-cities">
    <div class="header">
      <h2 class="title">
        <strong>Où investir</strong>
        en {{ currentYear }} ?
      </h2>
      <div>
        <NuxtLink
          to="/ou-investir"
          no-prefetch
          @click="handleClick('Où investir')"
        >
          <uikit-button type="link">
            Voir toutes les villes
            <template #right-icon>
              <uikit-arrow-icon
                class="arrow-icon"
                gradient="no-gradient"
              />
            </template>
          </uikit-button>
        </NuxtLink>
      </div>
    </div>
    <div class="cities-wrapper">
      <div
        class="cities"
        ref="citiesContainer"
      >
        <invest-city-card
          v-for="city in cities"
          :key="city.postalCode"
          :ref="'cityCard' + city.name"
          :city="city"
        />
      </div>
      <div
        class="view-more left"
        @click="scrollLeft"
        v-if="scrollableLeft"
      >
        <uikit-arrow-thin-icon type="left" />
      </div>
      <div
        class="view-more right"
        @click="scrollRight"
        v-if="scrollableRight"
      >
        <uikit-arrow-thin-icon />
      </div>
    </div>
  </div>
</template>

<script setup>
import { UikitButton, UikitArrowThinIcon, UikitArrowIcon } from '@hz/ui-kit'
import InvestCityCard from './InvestCityCard.vue'

const investApi = useInvestApi()

const currentYear = new Date().getFullYear()
const citiesContainer = ref(null)
const scrollableLeft = ref(false)
const scrollableRight = ref(false)

const cities = useState('home-invest-cities', () => [
  { postalCode: 75000, name: 'Paris' },
  { postalCode: 33000, name: 'Bordeaux' },
  { postalCode: 69000, name: 'Lyon' },
  { postalCode: 31000, name: 'Toulouse' },
  { postalCode: 13000, name: 'Marseille' },
  { postalCode: 59000, name: 'Lille' },
  { postalCode: 44000, name: 'Nantes' },
  { postalCode: 34000, name: 'Montpellier' },
  { postalCode: 42000, name: 'Saint-Étienne' },
  { postalCode: 49000, name: 'Angers' },
  { postalCode: 38000, name: 'Grenoble' },
  { postalCode: 66000, name: 'Perpignan' },
])

const scrollLeft = () => {
  const firstHiddenCard = [...citiesContainer.value.children].reverse().find((city) => {
    return city.getBoundingClientRect().left - citiesContainer.value.getBoundingClientRect().left < 0
  })

  if (firstHiddenCard) {
    citiesContainer.value.scrollTo(
      firstHiddenCard.offsetLeft - citiesContainer.value.clientWidth + firstHiddenCard.offsetWidth,
      0,
    )
  }
}

const scrollRight = () => {
  const firstHiddenCard = [...citiesContainer.value.children].find((city) => {
    return (
      citiesContainer.value.getBoundingClientRect().left +
        citiesContainer.value.clientWidth -
        city.getBoundingClientRect().right <
      0
    )
  })

  if (firstHiddenCard) {
    citiesContainer.value.scrollTo(firstHiddenCard.offsetLeft, 0)
  }
}

onMounted(() => {
  citiesContainer.value.addEventListener('scroll', handleScroll)
  handleScroll()
})
onUnmounted(() => {
  if (citiesContainer.value) {
    citiesContainer.value.removeEventListener('scroll', handleScroll)
  }
})

const handleScroll = () => {
  scrollableLeft.value = citiesContainer.value.scrollLeft > 0
  scrollableRight.value =
    citiesContainer.value.scrollWidth - citiesContainer.value.scrollLeft !== citiesContainer.value.clientWidth
}

const handleClick = (destination) => useTracking().track('[HP] Clic CTA', { destination })

if (!cities.value[0].hasOwnProperty('price')) {
  const { data: citiesPerformance } = await useLazyAsyncData('home-invest-cities', () =>
    investApi.getCitiesPerformance({
      postalCodes: cities.value.flatMap((city) => city.postalCode),
    }),
  )

  const mapCitiesPerformance = (citiesPerformance) =>
    (cities.value = citiesPerformance.map((city) => ({
      ...city,
      name: cities.value.find((investCity) => investCity.postalCode.toString() === city.postalCode.toString())
        .name,
    })))

  if (import.meta.client) {
    watch(citiesPerformance, mapCitiesPerformance)
  } else {
    mapCitiesPerformance(citiesPerformance.value)
  }
}
</script>

<style lang="less" scoped>
.invest-cities {
  max-width: 1400px;
  padding: 40px 80px;
  margin: auto;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .arrow-icon {
    width: 18px;
    height: auto;
  }

  .title {
    font-size: 33px;
    font-weight: var(--ds-weight-semi-bold);

    strong {
      color: var(--ds-color-primary-100);
      font-weight: var(--ds-weight-semi-bold);
    }
  }

  .cities-wrapper {
    position: relative;
    margin-top: 40px;

    .cities {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(12, minmax(285px, 1fr));
      gap: 32px;
      overflow-x: auto;
      scroll-behavior: smooth;
    }

    .view-more {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--ds-color-primary-100);
      width: 56px;
      height: 56px;
      border-radius: 100%;
      display: grid;
      place-items: center;
      color: var(--ds-color-white-100);
      cursor: pointer;

      &.left {
        left: -28px;
      }

      &.right {
        right: -28px;
      }
    }
  }

  @media @bp-md-down {
    padding: 40px 0;

    .title {
      font-size: var(--ds-font-xl);
    }

    .header {
      padding: 0 30px;
      flex-direction: column;
    }

    .cities-wrapper {
      .cities {
        padding: 0 30px;
      }

      .view-more {
        display: none;
      }
    }
  }
}
</style>
